<template>
  <div id="app" class="app-body">
    <div class="p-3">
      <div class="columns">
        <div class="column">
          <div class="spacer-small"></div>
          <div
            v-if="!loading && productDetail && !showPaymentSuccessMessage"
            class="columns is-vcentered"
          >
            <div class="column pb-5">
              <p class="is-subtitle is-secondary is-bold pb-5">
                Choose a Package for {{ productDetail.name }}
              </p>
              <div v-if="productDetail" class="mt-5">
                <div class="columns">
                  <div
                    v-for="packageDetail in productDetail.package"
                    :key="packageDetail.id"
                    class="column"
                  >
                    <div
                      class="box has-text-centered has-pointer"
                      @click="selectPackage(packageDetail)"
                    >
                      <p class="is-bold is-subtitle-small">
                        {{ packageDetail.name }}
                      </p>
                      <div class="columns is-mobile mt-4">
                        <div class="column"></div>
                        <div class="column is-narrow">
                          <div class="has-bg-gray-circle p-4">
                            <p
                              class="is-subtitle has-text-primary is-bold mt-1"
                            >
                              {{ packageDetail.workingDays }}
                            </p>
                            <p class="is-small m-0">Days</p>
                          </div>
                        </div>
                        <div class="column"></div>
                      </div>

                      <p class="is-small mt">
                        {{ packageDetail.description | limitTo(36) }}
                      </p>
                      <p class="is-small mt-4">Revision Count</p>
                      <p class="is-subtitle-small is-bold">
                        {{ packageDetail.revisionCount }}
                      </p>
                      <p class="is-small mt-4">Price</p>
                      <p class="is-secondary is-subtitle-small is-bold">
                        {{ packageDetail.price | currency }}
                      </p>
                      <b-button
                        class="mt-4"
                        :type="
                          selectedPackage == packageDetail
                            ? 'is-info'
                            : 'is-gray'
                        "
                      >
                        {{
                          selectedPackage == packageDetail
                            ? "Selected"
                            : "Select"
                        }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-5">
              <img
                src="@/assets/img/misc/payment_illustration.svg"
                height="100%"
              />
            </div>
          </div>
          <Loading v-if="loading" />
        </div>
      </div>
      <div
        v-if="selectedPackage && !showPaymentSuccessMessage && !loading"
        class="box mt-5 p-5"
        id="payment-detail"
      >
        <p class="is-subtitle-small is-secondary">Purchase Details</p>
        <div class="columns is-mobile mt-5">
          <div class="column pb-0">
            <p class="is-small">Service - Package</p>
          </div>
        </div>
        <hr class="m-0 mb-2" />
        <div class="columns is-mobile">
          <div class="column">
            <span>{{ productDetail.name }}</span> -
            <span class="is-secondary is-bold">{{ selectedPackage.name }}</span>
          </div>
        </div>
        <p class="is-small mt-4">Description</p>
        <hr class="m-0 mb-2" />
        <p>
          {{ selectedPackage.description }}
        </p>
        <p class="is-small mt-5">Price</p>
        <hr class="m-0 mb-2" />
        <p class="is-primary is-bold is-subtitle-small">
          {{ selectedPackage.price | currency }}
        </p>
        <b-button
          class="my-5"
          type="is-info"
          icon-left="check"
          @click="initiateCheckout"
        >
          Purchase
        </b-button>
      </div>
    </div>
    <!-- Modals Start -->
    <b-modal v-model="showBankTransferModal" has-modal-card>
      <div class="modal-card has-text-centered">
        <section class="modal-card-body pt-5">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="modal-card-title my-5">Make Payment</p>
              <img
                src="@/assets/img/misc/Payment_success_illustration.svg"
                height="100%"
              />
              <p class="my-4">
                Kindly make deposit of
                <span class="is-bold">
                  {{ selectedPackage ? selectedPackage.price : 0 }} PKR
                </span>
                to Brandeeq's Bank Account:
              </p>

              <div class="columns">
                <div class="column"></div>
                <div class="column is-narrow">
                  <b-message class="has-text-left" type="is-info">
                    Title: Brandeeq (Private) Limited <br />
                    Account Number: 0306-0106924810 <br />
                    Bank: Meezan Bank <br />
                    Branch: F-11 Markaz Branch, Islamabad
                  </b-message>
                </div>
                <div class="column"></div>
              </div>

              <p class="is-bold my-4">
                Enter your transaction reference number and click Submit once
                you have deposited the amount
              </p>

              <div class="columns is-vcentered">
                <div class="column is-narrow">
                  <p class="my-4">Enter Transaction Reference No:</p>
                </div>
                <div class="column">
                  <b-input v-model="userTransactionId"></b-input>
                </div>
                <div class="column is-narrow">
                  <b-button @click="bankTransferCheckout" type="is-primary">
                    Submit
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>

    <b-modal v-model="showPaymentFailedModal" has-modal-card>
      <div class="modal-card has-text-centered">
        <section class="modal-card-body pt-5">
          <div class="columns is-vcentered">
            <div class="column">
              <img
                src="@/assets/img/misc/Payment_fail_illustration.svg"
                height="100%"
              />
              <p class="modal-card-title my-5">Payment Failed</p>
              <p>
                {{ paymentFailiureMessage }}
              </p>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Close"
            @click="
              () => {
                this.showPaymentFailedModal = false;
              }
            "
          />
        </footer>
      </div>
    </b-modal>

    <div v-if="showPaymentSuccessMessage" class="modal-card has-text-centered">
      <section class="modal-card-body pt-5">
        <div class="columns is-vcentered">
          <div class="column">
            <img
              src="@/assets/img/misc/Payment_success_illustration.svg"
              height="100%"
            />
            <p class="modal-card-title my-5">Processing Payment</p>
            <p>Your order will begin once we have confirmed your payment</p>
            <b-button
              class="mt-5"
              label="Go to Dashboard"
              type="is-info"
              icon-right="arrow-right"
              @click="
                () => {
                  this.$router.push({ name: 'Dashboard' });
                }
              "
            />
          </div>
        </div>
      </section>
    </div>

    <!-- Modals End -->
  </div>
</template>

<script>
import Loading from "../../components/Loading.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "ProductDetail",
  components: { Loading },
  data() {
    return {
      loading: false,
      selectedPackage: undefined,
      showPaymentFailedModal: false,
      showPaymentSuccessMessage: false,
      showBankTransferModal: false,
      paymentFailiureMessage: undefined,
      checkoutPackage: undefined,
      userTransactionId: undefined,
    };
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("getProductDetail", { id: this.productId });
    this.loading = false;
  },
  watch: {
    selectedPackage() {
      this.$nextTick(() =>
        document.getElementById("payment-detail").scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        })
      );
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    productDetail() {
      return this.$store.state.productDetail;
    },
    productId() {
      return this.$route.query.id;
    },
  },
  methods: {
    selectPackage(packageObj) {
      this.selectedPackage = packageObj;
    },
    initiateForreeCheckout() {
      const checkout = async (payload) => {
        if (payload.bill_status && payload.bill_status == "paid") {
          const request = {
            paymentReferenceId: payload.reference_number,
            productId: this.productId,
            packageId: this.checkoutPackage._id,
          };
          this.loading = true;
          await this.$store.dispatch("createOrder", request);
          this.loading = false;
          window.scrollTo(0, 0);
          this.showPaymentSuccessMessage = true;
        } else {
          this.paymentFailiureMessage = payload.message;
          this.showPaymentFailedModal = true;
        }
      };
      this.checkoutPackage = this.selectedPackage;
      var bill = {
        key: "922913e4-8270-401f-82c6-219c1a85dbdc",
        amount: this.selectedPackage.price,
        create_bill: true,
        reference_number: uuidv4(),
        callback: checkout,
      };
      /*eslint-disable-next-line*/
      initiateForeeCheckout(bill);
    },
    initiateCheckout() {
      this.showBankTransferModal = true;
      this.checkoutPackage = this.selectedPackage;
    },
    async bankTransferCheckout() {
      if (this.userTransactionId) {
        const request = {
          paymentReferenceId: uuidv4(),
          transactionId: this.userTransactionId,
          productId: this.productId,
          packageId: this.checkoutPackage._id,
        };
        this.loading = true;
        this.showBankTransferModal = false;
        await this.$store.dispatch("createOrder", request);
        this.loading = false;
        window.scrollTo(0, 0);
        this.showPaymentSuccessMessage = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
